import HTMLReactParser from "html-react-parser";
import React from "react";
import { ColoredContainer } from "./Colored.styles";

const Colored = (props) => {
  const { ctitle, cinfo } = props;

  return (
    <ColoredContainer>
      <div className="container container--xsmall">
        {ctitle && HTMLReactParser(ctitle)}
        {cinfo && cinfo.map((text, index) => {
          return (
            <div key={index} className="colored-text">
              {HTMLReactParser(text.bText)}
            </div>
          );
        })}
      </div>
    </ColoredContainer>
  );
};

export default Colored;
